export function newsTabSelect() {
  const newsTabTrigger = document.querySelectorAll('.js-newsTab');
  const newsTabList = Array.prototype.slice.call(newsTabTrigger, 0);

  const newsTabBoxTrigger = document.querySelectorAll('.js-newsTabBox');
  const newsTabBoxList = Array.prototype.slice.call(newsTabBoxTrigger, 0);

  // メガメニューの非表示

  // 対応したメガメニューの表示
  newsTabList.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      // newsのメニューリストから全てのis-selectedを削除
      for (let i = 0; i < newsTabList.length; i++) {
        newsTabList[i].classList.remove('is-selected');
      }
      targetBox.classList.add('is-selected');

      const newsTabData = targetBox.dataset.tab;

      // 選択したメガメニューに対応したメニューにclass付与で表示
      newsTabJudge(newsTabData);
    });
  });

  // 選択したタブと一致したメガメニューの判別用の関数
  function newsTabJudge(targetBox) {
    const dataId = document.getElementById(targetBox);

    for (let i = 0; i < newsTabBoxList.length; i++) {
      newsTabBoxList[i].classList.remove('is-view');
    }

    dataId.classList.add('is-view');
  }
}
