export function headerAction() {
  const body = document.querySelector('body');
  const hamburgerTrigger = document.querySelector('.js-hamburger');
  const gnavTrigger = document.querySelector('.js-gnav');

  const headerSearch = document.querySelector('.js-headerSearch');
  const headerInput = document.querySelector('.js-headerInput');

  hamburgerTrigger.addEventListener('click', () => {
    if (hamburgerTrigger.classList.contains('is-active')) {
      body.classList.remove('is-fixed');
      hamburgerTrigger.classList.remove('is-active');
      gnavTrigger.classList.remove('is-view');
    } else {
      body.classList.add('is-fixed');
      hamburgerTrigger.classList.add('is-active');
      gnavTrigger.classList.add('is-view');
    }
  });

  headerSearch.addEventListener('mouseover', () => {
    headerSearch.classList.add('is-focus');
  });

  // headerSearch.addEventListener('mouseleave', () => {
  //   headerSearch.classList.remove('is-focus');
  // });
}
