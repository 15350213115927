export function megaMenu() {
  const megaTrigger = document.querySelectorAll('.js-mega');
  const megaTriggerList = Array.prototype.slice.call(megaTrigger, 0);

  const megaBoxTrigger = document.querySelectorAll('.js-megaBox');
  const megaBoxTriggerList = Array.prototype.slice.call(megaBoxTrigger, 0);

  // メガメニューの非表示

  // 対応したメガメニューの表示
  megaTriggerList.forEach(targetBox => {
    targetBox.addEventListener('mouseover', () => {
      const megaData = targetBox.dataset.mega;

      // 全メガメニュータブのis-selectedを削除
      for (let i = 0; i < megaTriggerList.length; i++) {
        megaTriggerList[i].classList.remove('is-selected');
      }

      // 表示したいメガメニューのタブにclassを付与
      targetBox.classList.add('is-selected');

      // 選択したメガメニューに対応したメニューにclass付与で表示
      megaMenuJudge(megaData);
    });
  });

  // 選択したタブと一致したメガメニューの判別用の関数
  function megaMenuJudge(targetBox) {
    const dataId = document.getElementById(targetBox);

    for (let i = 0; i < megaBoxTriggerList.length; i++) {
      megaBoxTriggerList[i].classList.remove('is-view');
    }

    dataId.classList.add('is-view');
  }
}
